[data-will-change='true'] {
  will-change: opacity;
}

[data-delayer] {
  position: static;
  z-index: unset;
}

[data-contain='strict'] {
  contain: strict;
}

[data-contain='paint'] {
  contain: paint;
}

[data-contain='layout'] {
  contain: layout;
}

[data-transition-button-background] {
  transition: opacity 200ms ease-out;
}

[data-web-user-select-none] {
  user-select: none;
}

[data-use-inset-padding-bottom] {
  padding-bottom: env(safe-area-inset-bottom);
}

[data-content-visibility-auto] {
  content-visibility: auto;
}

ul {
  padding-inline-start: 16px;
}
